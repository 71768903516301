function randomNumber (min, max) {
    return (Math.random() * (max - min)) + min
}

function isSuccessStatusCode (code) {
    // file:/// ios wierd thing
    if (code === 0) {
        return true
    }

    if (code >= 200 && code < 300) {
        return true
    }

    if (code === 422) {
        return true
    }

    return false
}
/* eslint-disable prefer-const */
export default (url, options = {}) => {
    return new Promise((resolve, reject) => {
        let request = new XMLHttpRequest(),
            method = options.method || 'GET',
            data = options.data || null,
            formData = null,
            nocache = options.nocach || false,
            token = options.token || null,
            mimeType = options.mimeType || 'application/json'

        if (nocache) {
            if (url.indexOf('?') !== -1) {
                url += '&' + randomNumber(1000, 9999)
            } else {
                url += '?' + randomNumber(1000, 9999)
            }
        }

        if (data) {
            formData = new FormData()
            Object.keys(data).forEach(v => {
                formData.append(v, data[v])
            })
        }

        request.open(method, url, true)

        if (!token) {
            token = window.localStorage.getItem('bearer-token')
        }

        if (token) {
            request.setRequestHeader('Authorization', 'Bearer ' + token)
        }

        request.overrideMimeType(mimeType)

        /**
         * Version 3 - 2022-07-29
         * -- Added program upgrades
         * -- Removed premium offers
         */
        request.setRequestHeader('X-API-Version', 3)

        request.onload = () => {
            if (isSuccessStatusCode(request.status)) {
                try {
                    if (mimeType === 'application/json') {
                        resolve(JSON.parse(request.responseText))
                    } else {
                        resolve(request.responseText)
                    }
                } catch (error) {
                    reject(new Error('Resouce is invalid. (' + url + ')'))
                }
            } else if (request.status === 404) {
                reject(new Error('Resouce not found. (' + url + ')'))
            } else {
                reject(new Error('Resource not loaded. (Status code: ' + request.status + ', ' + url + ')'))
            }
        }

        request.onerror = () => {
            reject(new Error('Resource not loaded. (' + url + ')'))
        }

        request.send(formData)
    })
}
