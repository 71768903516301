import store from './../../store'
import i18n from './../../i18n'

function setSearchParams (params) {
    if ('pczip' in params) {
        store.commit('setSearchPcZip', params.pczip)
    }
    if ('city' in params) {
        store.commit('setSearchCity', params.city)
    }

    if ('keywords' in params) {
        store.commit('setSearchKeywords', params.keywords)
    }

    if ('longitude' in params) {
        store.commit('setSearchLongitude', params.longitude)
    }
    if ('latitude' in params) {
        store.commit('setSearchLatitude', params.latitude)
    }
}

let watcher = null,
    working = false,
    longitude = null,
    latitude = null,
    errors = 0

export default async function (mode, params) {
    params = params || {}

    if (mode === 'geo') {
        // Prioritize manual geo location
        if ('longitude' in params && 'latitude' in params &&
            params.longitude !== null && params.latitude !== null
        ) {
            store.commit('setSearchMode', 'geo')
            setSearchParams(params)
            return
        }

        // Watcher activated so just return its results
        if (watcher !== null && longitude !== null && latitude !== null) {
            store.commit('setSearchMode', 'geo')
            setSearchParams(params)
            store.commit('setSearchLongitude', longitude)
            store.commit('setSearchLatitude', latitude)
            return
        }

        working = true
        longitude = null
        latitude = null
        errors = 0

        watcher = navigator.geolocation.watchPosition(
            pos => {
                longitude = pos.coords.longitude
                latitude = pos.coords.latitude
                working = false
                if (errors > 0) {
                    --errors
                }
            },
            error => {
                let key

                errors += 2

                // Only show if failed first request
                if (working) {
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            key = 'search__error__geo__denied'
                            break
                        case error.POSITION_UNAVAILABLE:
                        case error.TIMEOUT:
                        case error.UNKNOWN_ERROR:
                        default:
                            key = 'search__error__geo'
                            break
                    }

                    alert(i18n.global.t(key))
                    navigator.geolocation.clearWatch(watcher)
                } else if (errors > 10) {
                    navigator.geolocation.clearWatch(watcher)
                }

                working = false
            },
            {
                enableHighAccuracy: false,
                timeout: 5000,
                maximumAge: 0
            }
        )

        return new Promise(resolve => {
            const intervalId = setInterval(() => {
                if (!working) {
                    clearInterval(intervalId)

                    if (longitude !== null && latitude !== null) {
                        store.commit('setSearchMode', 'geo')
                        setSearchParams(params)
                        store.commit('setSearchLongitude', longitude)
                        store.commit('setSearchLatitude', latitude)
                    } else {
                        // Fall back to pczip
                        watcher = null
                        store.commit('setSearchMode', null)
                        setSearchParams(params)
                        if (store.state.searchPcZip === null && store.state.searchCity === null) {
                            store.commit('setSearchPcZip', store.state.member.pczip)
                        }
                    }

                    resolve()
                }
            }, 50)
        })
    }

    if (watcher) {
        navigator.geolocation.clearWatch(watcher)
        watcher = null
    }

    store.commit('setSearchMode', null)
    setSearchParams(params)
    if (store.state.searchPcZip === null && store.state.searchCity === null) {
        store.commit('setSearchPcZip', store.state.member.pczip)
    }
}
