import {createApp} from 'vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import App from './App.vue'

import clickOutside from './directives/click-outside/directive'

// TODO: Determine if this is the right way
if (process.env.NODE_ENV === 'production') {
    document.addEventListener('deviceready', () => {
        if (!window.device) {
            window.device = {platform: 'Browser'}
        }

        store.commit('setPlatform', window.device.platform)

        start()
        .then(() => {
            if (window.cordova && window.cordova.InAppBrowser) {
                window.open = window.cordova.InAppBrowser.open
            }

            if (window.navigator.splashscreen) {
                window.navigator.splashscreen.hide()
            }
        })
    }, false)
} else {
    window.device = {platform: 'Browser'}
    store.commit('setPlatform', window.device.platform)
    start()
}

async function start () {
    await store.dispatch('start')

    createApp(App)
    .use(store)
    .use(i18n)
    .use(router)
    .directive('click-outside', clickOutside)
    .mount('#app_container')
}
