import store from './../store'

export default () => {
    window.localStorage.removeItem('credentials')
    window.localStorage.removeItem('bearer-token')
    window.localStorage.removeItem('bearer-token-expiry')
    window.localStorage.removeItem('access-id')

    store.commit('setAccess', false)
    store.commit('setMember', null)
    store.commit('setAccessId', null)
    store.commit('setProgramId', null)

    store.commit('setVerified', false)
    store.commit('setMemberships', null)
    store.commit('setPages', null)
}
