<template>
    <div id="app" :class="['app', classes]" :style="styles">
        <router-view/>
    </div>
</template>

<script>
    export default {
        mounted () {
            this.interval = setInterval(() => {
                this.$store.commit('setAppHeight', window.innerHeight)
                this.$store.commit('setPageHeight', this.$el.offsetHeight)
            }, 100)
        },
        beforeDestroy () {
            if (this.interval !== null) {
                clearInterval(this.interval)
            }
        },
        data () {
            return {
                interval: null
            }
        },
        computed: {
            programStyles () {
                if (!this.$store.state.program ||
                    !this.$store.state.program.styles
                ) {
                    return null
                }

                return this.$store.state.program.styles
            },

            classes () {
                let classList = [],
                    platform = this.$store.state.platform

                if (platform) {
                    classList.push('app_platform_' + platform.toLowerCase())
                }

                return classList.join(' ')
            },
            styles () {
                if (!this.programStyles) {
                    return null
                }
                return {
                    // Primary Background
                    '--main-anchor-color': this.programStyles.color_primary_background,
                    '--main-anchor-icon-color': this.programStyles.color_primary_background,
                    '--main-anchor-color_active': this.programStyles.color_primary_background,
                    '--main-anchor-icon-color_active': this.programStyles.color_primary_background,

                    '--upper-border-color': this.programStyles.color_primary_background,
                    '--upper-background-color': this.programStyles.color_primary_background,

                    '--bottom-color': this.programStyles.color_primary_background,
                    '--bottom-icon-color': this.programStyles.color_primary_background,
                    '--bottom-color_active': this.programStyles.color_primary_background,
                    '--bottom-icon-color_active': this.programStyles.color_primary_background,

                    '--bottom-anchor-border-color': this.programStyles.color_primary_background,
                    '--bottom-anchor-color': this.programStyles.color_primary_background,
                    '--bottom-anchor-icon-color': this.programStyles.color_primary_background,
                    '--bottom-anchor-border-color_active': this.programStyles.color_primary_background,
                    '--bottom-anchor-color_active': this.programStyles.color_primary_background,
                    '--bottom-anchor-icon-color_active': this.programStyles.color_primary_background,
                    '--button-no-border-color': this.programStyles.color_primary_background,
                    '--button-no-background-color': this.programStyles.color_primary_background,

                    // Primary Background Hover
                    '--main-color': this.programStyles.color_primary_background_hover,
                    '--main-icon-color': this.programStyles.color_primary_background_hover,

                    '--upper-border-color_active': this.programStyles.color_primary_background_hover,
                    '--upper-background-color_active': this.programStyles.color_primary_background_hover,

                    '--button-yes-border-color_active': this.programStyles.color_primary_background_hover,
                    '--button-yes-background-color_active': this.programStyles.color_primary_background_hover,
                    '--button-no-border-color_active': this.programStyles.color_primary_background_hover,
                    '--button-no-background-color_active': this.programStyles.color_primary_background_hover,

                    // Primary Text
                    '--upper-color': this.programStyles.color_primary_text,
                    '--upper-icon-color': this.programStyles.color_primary_text,
                    '--upper-anchor-color': this.programStyles.color_primary_text,
                    '--upper-anchor-icon-color': this.programStyles.color_primary_text,

                    '--button-no-color': this.programStyles.color_primary_text,

                    // Primary Text Hover
                    '--upper-color_active': this.programStyles.color_primary_text_hover,
                    '--upper-icon-color_active': this.programStyles.color_primary_text_hover,
                    '--upper-anchor-color_active': this.programStyles.color_primary_text_hover,
                    '--upper-anchor-icon-color_active': this.programStyles.color_primary_text_hover,

                    '--button-yes-color_active': this.programStyles.color_primary_text_hover,
                    '--button-yes-icon-color_active': this.programStyles.color_primary_text_hover,

                    '--button-no-color_active': this.programStyles.color_primary_text_hover
                    // '--button-no-icon-color': this.programStyles.color_primary_text_hover,
                }
            }
        }
    }
</script>
<style lang="scss">
    @import './styles/normalize.scss';
    @import './styles/variables.scss';
    @import './styles/css-variables.scss';
    @import './styles/initialize.scss';
    @import './styles/base.scss';

    .app_platform_ios {
        padding-top: constant(safe-area-inset-top); // iOs 11
        padding-top: env(safe-area-inset-top); // iOs 11+ (feature)

        padding-right: constant(safe-area-inset-right); // iOs 11
        padding-right: env(safe-area-inset-right); // iOs 11+ (feature)

        padding-bottom: constant(safe-area-inset-bottom); // iOs 11
        padding-bottom: env(safe-area-inset-bottom); // iOs 11+ (feature)

        padding-left: constant(safe-area-inset-left); // iOs 11
        padding-left: env(safe-area-inset-left); // iOs 11+ (feature)
    }
</style>

