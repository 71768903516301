import httpRequest from './httpRequest.js'
import apiUrl from './apiUrl.js'
import store from './../store'

export default async credentials => {
    const url = store.state.urlApi + '/access/login' +
        '?$options=include-user,include-access-id,include-program-id,' +
        'include-favorites,include-change-password'

    return httpRequest(url, {
        method: 'POST',
        data: credentials
    })
    .then(data => {
        if (!data.errors) {
            window.localStorage.setItem('credentials', JSON.stringify(credentials))
            window.localStorage.setItem('bearer-token', data.token)
            window.localStorage.setItem('bearer-token-expiry', data.expiry)
            window.localStorage.setItem('access-id', JSON.stringify(data.access_id))

            store.commit('setSearchMode', window.localStorage.getItem('search-mode'))
            store.commit('setSearchPcZip', window.localStorage.getItem('search-pczip'))
            store.commit('setSearchCity', window.localStorage.getItem('sarch-city'))

            if (store.state.searchPcZip === null && store.state.searchCity === null) {
                store.commit('setSearchPcZip', data.member.pczip)
            }

            store.commit('setAccess', true)
            store.commit('setMember', data.member)
            store.commit('setAccessId', data.access_id)
            store.commit('setProgramId', data.program_id)
            store.commit('setFavorites', data.favorites > 0)
            store.commit('setChangePassword', data.change_password)
        }

        httpRequest(apiUrl(store.state, '/content'))
        .then(data => {
            store.commit('setPages', data)
        })
        .catch(() => {
            store.commit('setPages', [])
        })

        return Promise.resolve(data)
    })
}
