import {createRouter, createWebHashHistory} from 'vue-router'
import store from './../store'
import routes from './routes'

import isProgramRoute from './../scripts/router/isProgramRoute.js'

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior (to, from, savedPosition) {
        if ((to.name === 'offer-index-savings' ||
            to.name === 'offer-index-favorites' ||
            to.name === 'offer-index') && store.state.offersScroll
        ) {
            const offerScroll = store.state.offersScroll

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({left: 0, top: offerScroll})
                }, 250)
            })
        } else if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    }
})

router.beforeEach((to, from, next) => {
    let override = false

    if (isProgramRoute(to.name)) {
        if (!store.state.access) {
            next('/')
            return
        }

        // Load program specific things
        // Offer index handled
        if (!store.state.program) {
            override = true
            store.dispatch('loadProgramAsync', true)
        }
        if (!store.state.categories) {
            override = true
            store.dispatch('loadCategoriesAsync', true)
        }
        if (!store.state.memberships) {
            override = true
            store.dispatch('loadMembershipsAsync', true)
        }
    } else {
        if (store.state.access) {
            // If first time visit and logged in still, auto rediect to offers
            if (!store.state.previousRoute && to.name !== 'offer-index') {
                next({name: 'offer-index'})
                return
            }
        }
    }

    // This feels very hacky...
    if (to.name === 'offer-index-savings') {
        if (store.state.offersFilter !== 'savings') {
            override = true
            store.commit('setOffersFilter', 'savings')
        }

        if (!store.state.offersSavings ||
            (store.state.offersSavingsIndex !== 0 && !store.state.offersLockState)
        ) {
            override = true
            store.commit('setOffersSavingsIndex', 0)
        }

        store.commit('setOffersLockState', false)
        store.dispatch('updateOffersAsync', override)
    } else if (to.name === 'offer-index-favorites') {
        if (store.state.offersFilter !== 'favorites') {
            override = true
            store.commit('setOffersFilter', 'favorites')
        }

        if (!store.state.offersFavorites ||
            (store.state.offersFavoritesIndex !== 0 && !store.state.offersLockState)
        ) {
            override = true
            store.commit('setOffersFavoritesIndex', 0)
        }

        store.commit('setOffersLockState', false)
        store.dispatch('updateOffersAsync', override)
    } else if (to.name === 'offer-index') {
        if (store.state.offersFilter !== null) {
            override = true
            store.commit('setOffersFilter', null)
        }

        if (!store.state.offers ||
            (store.state.offersIndex !== 0 && !store.state.offersLockState)
        ) {
            override = true
            store.commit('setOffersIndex', 0)
        }

        store.commit('setOffersLockState', false)
        store.dispatch('updateOffersAsync', override)
    } else if (to.name === 'offer-item' ||
        to.name === 'offer-item-location'
    ) {
        store.commit('setOfferId', to.params.offerId)
        store.commit('setOfferLocationId', to.params.locationId || null)
        store.commit('setOffer', null)
        store.commit('setOfferState', 'view')
        store.dispatch('updateOfferAsync')
    } else if (to.name === 'faq') {
        store.commit('setPage', null)
        store.commit('setPageAlias', to.name)
        store.dispatch('updatePageAsync')
    } else if (to.name === 'page') {
        store.commit('setPage', null)
        store.commit('setPageAlias', to.params.pageAlias)
        store.dispatch('updatePageAsync')
    }

    next()
})
/* router.beforeResolve((to, from, next) => {
    next()
}) */
router.afterEach((to, from, next) => {
    store.commit('setPreviousRoute', from.name || 'home')
})

export default router
