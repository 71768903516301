import clearToken from './clearToken.js'
import httpRequest from './httpRequest.js'
import apiUrl from './apiUrl.js'
import store from './../store'

export default async () => {
    const token = window.localStorage.getItem('bearer-token')
    const expiry = window.localStorage.getItem('bearer-token-expiry')
    const accessId = window.localStorage.getItem('access-id')
    const verified = store.state.verified

    if (verified) { // Check previous verificaiton state
        return Promise.resolve({
            token,
            expiry
        })
    } else if (verified !== null) {
        return Promise.reject(new Error('Token verification failed.'))
    }

    if (token === null || expiry === null) {
        clearToken()

        return Promise.reject(new Error('Token verification failed.'))
    }

    const url = store.state.urlApi + '/access/login' +
        '?$options=include-user,include-access-id,include-program-id,' +
        'include-favorites,include-change-password' +
        '&$accessId=' + accessId

    let credentials = window.localStorage.getItem('credentials')
    credentials = JSON.parse(credentials)

    return httpRequest(url, {
        method: 'POST',
        data: credentials
    })
    .then(data => {
        if (!data.member) {
            clearToken()
            return Promise.reject(new Error('Token verification failed.'))
        }

        window.localStorage.setItem('bearer-token', data.token)
        window.localStorage.setItem('bearer-token-expiry', data.expiry)
        window.localStorage.setItem('access-id', JSON.stringify(data.access_id))

        store.commit('setSearchMode', window.localStorage.getItem('search-mode'))
        store.commit('setSearchPcZip', window.localStorage.getItem('search-pczip'))
        store.commit('setSearchCity', window.localStorage.getItem('sarch-city'))

        if (store.state.searchPcZip === null && store.state.searchCity === null) {
            store.commit('setSearchPcZip', data.member.pczip)
        }

        store.commit('setAccess', true)
        store.commit('setVerified', true)
        store.commit('setMember', data.member)
        store.commit('setAccessId', data.access_id)
        store.commit('setProgramId', data.program_id)
        store.commit('setFavorites', data.favorites > 0)
        store.commit('setChangePassword', data.change_password)

        httpRequest(apiUrl(store.state, '/content'))
        .then(data => {
            store.commit('setPages', data)
        })
        .catch(() => {
            store.commit('setPages', [])
        })

        return data
    })
    .catch(error => {
        clearToken()

        return Promise.reject(error)
    })
}
