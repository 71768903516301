export default (state, path, data = {}) => {
    let url = state.urlApi

    const query = []

    if (state.localeCode) {
        url += '/' + state.localeCode.split('-')[0]
    }

    url += path

    if (state.accessId) {
        query.push('$accessId=' + state.accessId)
    }

    if (path === '/offers/index' ||
        path.substr(0, 14) === '/offers/index/' ||
        path === '/offers/item' ||
        path === '/offers/additional' ||
        path === '/options/keywords'
    ) {
        if (path.substr(14, 7) === 'savings') {
            query.push('$indexOffset=' + state.offersSavingsIndex)
        } else if (path.substr(14, 9) === 'favorites') {
            query.push('$indexOffset=' + state.offersFavoritesIndex)
        } else {
            query.push('$indexOffset=' + state.offersIndex)

            if (state.category) {
                query.push('cat=' + state.category.id)
            }

            if (state.searchMode === 'geo' && state.searchLongitute && state.searchLatitude) {
                query.push('gps_lng=' + encodeURIComponent(state.searchLongitute))
                query.push('gps_lat=' + encodeURIComponent(state.searchLatitude))
            } else {
                if (state.searchPcZip) {
                    query.push('pc=' + encodeURIComponent(state.searchPcZip))
                }

                if (state.searchCity) {
                    query.push('c=' + encodeURIComponent(state.searchCity))
                }
            }

            if (state.searchKeywords) {
                query.push('&k=' + encodeURIComponent(state.searchKeywords))
            }
        }
    }

    if (path === '/offers/item') {
        query.push('offerId=' + state.offerId)

        if (state.offerLocationId) {
            query.push('locationId=' + state.offerLocationId)
        }
    }

    if (path === '/offers/additional') {
        query.push('offerId=' + state.offerId)
    }

    if (path === '/program' && state.programId) {
        query.push('programId=' + state.programId)
    }

    for (const key of Object.keys(data)) {
        query.push(key + '=' + encodeURIComponent(data[key]))
    }

    if (query.length) {
        url += '?' + query.join('&')
    }

    return url
}
