import httpRequest from './httpRequest.js'
import store from './../store'

export default () => {
    let url = store.state.urlApi + '/offers/redeem' +
        '?$accessId=' + store.state.accessId

    url += '&offerId=' + store.state.offerId
    if (store.state.offerLocationId) {
        url += '&locationId=' + store.state.offerLocationId
    }

    return httpRequest(url)
}
