const isTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints > 0
const events = isTouch ? ['touchstart', 'click'] : ['click']
const bubbleInstances = [] // addEventListener useCapture false
const captureInstances = [] // addEventListener useCapture true

function onBubbleEvent (event) {
    bubbleInstances.forEach(({el, fn}) => {
        if (event.target !== el && !el.contains(event.target)) {
            fn && fn(event)
        }
    })
}

function onCaptureEvent (event) {
    captureInstances.forEach(({el, fn}) => {
        if (event.target !== el && !el.contains(event.target)) {
            fn && fn(event)
        }
    })
}

const directive = {
    beforeMount (el, binding) {
        if (binding.modifiers.capture) {
            captureInstances.push({el, fn: binding.value})
            if (captureInstances.length === 1) {
                events.forEach(e => document.addEventListener(e, onCaptureEvent, true))
            }
        } else {
            bubbleInstances.push({el, fn: binding.value})
            if (bubbleInstances.length === 1) {
                events.forEach(e => document.addEventListener(e, onBubbleEvent, false))
            }
        }
    },

    updated (el, binding) {
        if (typeof binding.value !== 'function') {
            throw new Error('Argument must be a function')
        }

        if (binding.modifiers.capture) {
            const instance = captureInstances.find(i => i.el === el)
            instance.fn = binding.value
        } else {
            const instance = bubbleInstances.find(i => i.el === el)
            instance.fn = binding.value
        }
    },

    unmounted (el, binding) {
        if (binding.modifiers.capture) {
            const instanceIndex = captureInstances.findIndex(i => i.el === el)
            captureInstances.splice(instanceIndex, 1)
            if (captureInstances.length === 0) {
                events.forEach(e => document.removeEventListener(e, onCaptureEvent, true))
            }
        } else {
            const instanceIndex = bubbleInstances.findIndex(i => i.el === el)
            bubbleInstances.splice(instanceIndex, 1)
            if (bubbleInstances.length === 0) {
                events.forEach(e => document.removeEventListener(e, onBubbleEvent, false))
            }
        }
    }
}

export default directive
