export default function (routeName) {
    switch (routeName) {
        case 'offer-index':
        case 'offer-index-favorites':
        case 'offer-index-savings':
            return true
        default:
            return false
    }
}
