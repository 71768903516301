import {createStore} from 'vuex'
import i18n from './../i18n'

import deleteToken from './../scripts/deleteToken.js'
import insertToken from './../scripts/insertToken.js'
import verifyToken from './../scripts/verifyToken.js'

import redeemOffer from './../scripts/redeemOffer.js'
import setSearchMode from './../scripts/update/setSearchMode.js'

import httpRequest from './../scripts/httpRequest.js'
import scrollbarWidth from './../scripts/scrollbarWidth.js'
import apiUrl from './../scripts/apiUrl.js'

const config = {}
const loadedLocales = []

// Root state object.
const state = {
    // URLs
    urlApi: null,
    urlSite: null,

    // Access
    access: false,
    verified: null,
    member: null,
    accessId: null,
    programId: null,

    // App States
    appHeight: 0,
    pageHeight: 0,
    pageState: null,
    modals: {},
    toggles: {},
    localeCode: null,
    loading: false,
    dataLists: {},
    changePassword: false,
    ignorePcZip: false,

    // Search States
    searchMode: null,
    searchPcZip: null,
    searchCity: null,
    searchKeywords: null,
    searchLongitute: null,
    searchLatitude: null,

    // Offer States
    favorites: false,
    category: null,

    // Offer Index States
    offersSearch: {},
    offersSavingsIndex: 0,
    offersFavoritesIndex: 0,
    offersIndex: 0,
    offersView: 'row',
    offersFilter: null,
    offersLockState: false, // Used to prevent router from reseting to index of 0
    offersScroll: 0,

    // Offer Item States
    offerId: null,
    offerLocationId: null,
    offerState: 'view',

    // Page States
    pageAlias: null,

    // Data
    program: null,
    categories: null,
    memberships: null,
    offersSavings: null,
    offersFavorites: null,
    offers: null,
    offersInline: null,
    offer: null,
    page: null,
    pages: null,
    additionalOffers: null,
    countries: null,
    times: null,

    // Internals
    previousRoute: null,
    scrollbarWidth: scrollbarWidth(),
    formIdCount: 0,
    platform: null
}

// Mutations are operations that actually mutates the state.
// Each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// Mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {
    // URLs
    setUrlApi (state, url) {
        state.urlApi = url
    },
    setUrlSite (state, url) {
        state.urlSite = url
    },

    // Access
    setAccess (state, access) {
        state.access = access
    },
    setVerified (state, verified) {
        state.verified = verified
    },
    setMember (state, member) {
        state.member = member
    },
    setAccessId (state, accessId) {
        state.accessId = accessId
    },
    setProgramId (state, programId) {
        state.programId = programId
    },

    // App States
    setAppHeight (state, value) {
        state.appHeight = value
    },
    setPageHeight (state, value) {
        state.pageHeight = value
    },
    setPageState (state, value) {
        state.pageState = value
    },
    showModal (state, name) {
        state.modals[name] = true
        state.modals = {...state.modals}

        state.pageState = 'modal'

        window.scrollTo(0, 0)
    },
    hideModal (state, name) {
        state.modals[name] = false
        state.modals = {...state.modals}

        const keys = Object.keys(state.modals)

        let found = false

        for (let i = 0; i < keys.length; ++i) {
            if (state.modals[keys[i]]) {
                found = true
                break
            }
        }

        state.pageState = (found ? 'modal' : null)
    },
    toggleOn (state, payload) {
        if (typeof payload.group === 'string' && payload.group !== '') {
            state.toggles[payload.group] = payload.toggle
        } else {
            state.toggles[payload.toggle] = true
        }
        state.toggles = {...state.toggles}
    },
    toggleOff (state, payload) {
        if (typeof payload.group === 'string' && payload.group !== '') {
            if (state.toggles[payload.group] === payload.toggle) {
                state.toggles[payload.group] = false
            }
        } else {
            state.toggles[payload.toggle] = false
        }
        state.toggles = {...state.toggles}
    },
    toggleOffMenus (state) {
        state.toggles.siteMenu = false
        state.toggles.starMenu = false
        state.toggles.searchMenu = false
        state.toggles.searchMenuCategories = false
        state.toggles.searchMenuMemberships = false
        state.toggles = {...state.toggles}
    },
    setLocaleCode (state, localeCode) {
        state.localeCode = localeCode
        window.localStorage.setItem('locale-code', localeCode)
        document.querySelector('html').setAttribute('lang', localeCode)
    },
    setLoading (state, value) {
        state.loading = value
    },
    setDataList (state, payload) {
        state.dataLists[payload.id] = payload

        state.dataLists = {...state.dataLists}
    },
    setChangePassword (state, value) {
        state.changePassword = value
    },
    setIgnorePcZip (state, value) {
        state.ignorePcZip = value
    },

    // Search States
    setSearchMode (state, value) {
        value = (value === '' ? null : value)

        state.searchMode = value
        window.localStorage.setItem('search-mode', value)
    },
    setSearchPcZip (state, value) {
        value = (value === '' ? null : value)

        state.searchPcZip = value
        window.localStorage.setItem('search-pczip', value)
    },
    setSearchCity (state, value) {
        value = (value === '' ? null : value)

        state.searchCity = value
        window.localStorage.setItem('search-city', value)
    },
    setSearchKeywords (state, value) {
        value = (value === '' ? null : value)

        state.searchKeywords = value
    },
    setSearchLongitude (state, value) {
        value = (value === '' ? null : value)

        state.searchLongitute = value
    },
    setSearchLatitude (state, value) {
        value = (value === '' ? null : value)

        state.searchLatitude = value
    },

    // Offer States
    setCategory (state, value) {
        state.category = value
    },
    setFavorites (state, value) {
        state.favorites = value
    },
    setFavoriteOn (state, id) {
        if (state.offer && state.offer.merchant.id === id) {
            state.offer.merchant.favorite = true
            state.offer = {...state.offer}
        }

        if (state.offers &&
            state.offers.items &&
            state.offers.items.length
        ) {
            for (let i = 0; i < state.offers.items.length; ++i) {
                if (state.offers.items[i].merchant_id === id) {
                    state.offers.items[i].favorite = true
                    state.offers = {...state.offers}
                    break
                }
            }
        }

        if (state.offersSavings &&
            state.offersSavings.items &&
            state.offersSavings.items.length
        ) {
            for (let i = 0; i < state.offersSavings.items.length; ++i) {
                if (state.offersSavings.items[i].merchant_id === id) {
                    state.offersSavings.items[i].favorite = true
                    state.offersSavings = {...state.offersSavings}
                    break
                }
            }
        }

        if (state.offersFavorites &&
            state.offersFavorites.items &&
            state.offersFavorites.items.length
        ) {
            for (let i = 0; i < state.offersFavorites.items.length; ++i) {
                if (state.offersFavorites.items[i].merchant_id === id) {
                    state.offersFavorites.items[i].favorite = true
                    state.offersFavorites = {...state.offersFavorites}
                    break
                }
            }
        }
    },
    setFavoriteOff (state, id) {
        if (state.offer && state.offer.merchant.id === id) {
            state.offer.merchant.favorite = false
            state.offer = {...state.offer}
        }

        if (state.offers &&
            state.offers.items &&
            state.offers.items.length
        ) {
            for (let i = 0; i < state.offers.items.length; ++i) {
                if (state.offers.items[i].merchant_id === id) {
                    state.offers.items[i].favorite = false
                    state.offers = {...state.offers}
                    break
                }
            }
        }

        if (state.offersSavings &&
            state.offersSavings.items &&
            state.offersSavings.items.length
        ) {
            for (let i = 0; i < state.offersSavings.items.length; ++i) {
                if (state.offersSavings.items[i].merchant_id === id) {
                    state.offersSavings.items[i].favorite = false
                    state.offersSavings = {...state.offersSavings}
                    break
                }
            }
        }

        if (state.offersFavorites &&
            state.offersFavorites.items &&
            state.offersFavorites.items.length
        ) {
            for (let i = 0; i < state.offersFavorites.items.length; ++i) {
                if (state.offersFavorites.items[i].merchant_id === id) {
                    state.offersFavorites.items[i].favorite = false
                    state.offersFavorites = {...state.offersFavorites}
                    break
                }
            }
        }
    },

    // Offer Index States
    setOffersSavingsIndex (state, index) {
        state.offersSavingsIndex = index
    },
    setOffersFavoritesIndex (state, index) {
        state.offersFavoritesIndex = index
    },
    setOffersIndex (state, index) {
        state.offersIndex = index
    },
    setOffersView (state, value) {
        state.offersView = value
    },
    setOffersFilter (state, value) {
        state.offersFilter = value
    },
    setOffersLockState (state, value) {
        state.offersLockState = value
    },
    setOffersScroll (state, value) {
        state.offersScroll = value
    },

    // Offer Item States
    setOfferId (state, value) {
        if (state.offerId !== value) {
            state.offerId = value
        }
    },
    setOfferLocationId (state, value) {
        if (state.offerLocationId !== value) {
            state.offerLocationId = value
        }
    },
    setOfferState (state, value) {
        state.offerState = value
    },

    // Page Item States
    setPageAlias (state, value) {
        if (state.pageAlias !== value) {
            state.pageAlias = value
        }
    },

    // Data
    clearData (state) {
        state.program = null
        state.memberships = null
        state.categories = null

        state.offers = null
        state.offer = null
        state.offerLocationId = null
        state.offerState = 'view'
    },
    setProgram (state, value) {
        state.program = value
    },
    setMemberships (state, memberships) {
        state.memberships = memberships
    },
    setCategories (state, categories) {
        state.categories = categories
    },
    setOffersSavings (state, offers) {
        state.offersSavings = offers
    },
    setOffersFavorites (state, offers) {
        state.offersFavorites = offers
    },
    setOffers (state, offers) {
        state.offers = offers
    },
    setOffersInline (state, offers) {
        state.offersInline = offers
    },
    setOffer (state, offer) {
        state.offer = offer
    },
    setPage (state, page) {
        state.page = page
    },
    setPages (state, pages) {
        state.pages = pages
    },
    setAdditionalOffers (state, data) {
        state.additionalOffers = data
    },
    setCountries (state, countries) {
        state.countries = countries
    },
    setTimes (state, times) {
        state.times = times
    },

    // Internal
    setPreviousRoute (state, route) {
        state.previousRoute = route
    },
    incrementFormIdCount (state) {
        ++state.formIdCount
    },

    setPlatform (state, platform) {
        platform = platform.toUpperCase()

        if (platform === 'ANDROID') {
            state.platform = 'Android'
        } else if (platform === 'IOS') {
            state.platform = 'iOS'
        } else if (platform === 'BROWSER') {
            state.platform = 'Browser'
        } else {
            state.platform = null
        }
    }
}

// Actions are functions that causes side effects and can involve
// asynchronous operations.
const actions = {
    start (context) {
        return httpRequest('config.json')
        .then(data => {
            config.options = data.options || {}
            config.locales = data.locales || {}
            config.locale = data.locale || 'en-US'
            config.pages = data.pages || {}

            context.commit('setUrlApi', data.urlApi)
            context.commit('setUrlSite', data.urlSite)

            return httpRequest(apiUrl(context.state, '/options/app'))
            .catch(() => {
                return {}
            })
        })
        .then(data => {
            config.options = {...config.options, ...data}

            return verifyToken()
            .catch(() => {})
            .then(() => {
                let locale = window.localStorage.getItem('locale-code') ||
                    context.getters.memberLocaleCode(config.locale)

                if (!(locale in config.locales)) {
                    locale = config.locale
                }

                return context.dispatch(
                    'setLocaleAsync',
                    locale
                )
            })
        })
    },

    doAction (context, payload) {
        switch (payload.action) {
            case 'login':
                return insertToken(payload.data)
                .catch(() => {})
                .then(error => {
                    context.commit('clearData')

                    return error
                })
            case 'logout':
                return deleteToken()
                .catch(() => {})
                .then(error => {
                    context.commit('clearData')

                    return error
                })
            case 'redeem':
                return redeemOffer()
                .catch(error => {
                    // TODO: Error popup saying coupon could not be redeemed or something.
                    // Login popup?, check local storage for credentials first?
                    return error
                })
            case 'forgot-password':
            case 'register':
                return httpRequest(
                    apiUrl(context.state, '/access/' + payload.action),
                    {
                        method: 'POST',
                        data: payload.data
                    }
                )
                .catch(error => {
                    return error
                })
            case 'validate-code':
                return httpRequest(apiUrl(
                    context.state,
                    '/access/validate-code',
                    {code: payload.data.code}
                ))
                .catch(error => {
                    return error
                })
            case 'profile':
            case 'change-password':
            case 'pczip':
                return httpRequest(
                    apiUrl(
                        context.state,
                        '/members/' + payload.action,
                        payload.actionParams || {}
                    ),
                    {
                        method: 'POST',
                        data: payload.data
                    }
                )
                .catch(error => {
                    return error
                })
            case 'request-information':
                return httpRequest(
                    apiUrl(context.state, '/service/' + payload.action),
                    {
                        method: 'POST',
                        data: payload.data
                    }
                )
                .catch(error => {
                    return error
                })
        }

        return Promise.reject(new Error('Invalid action.'))
    },

    async loadLocaleAsync (_context, localeCode) {
        return httpRequest('locales/' + localeCode + '.json')
        .then(data => {
            i18n.global.setLocaleMessage(localeCode, data)
        })
        .catch(_e => {
            i18n.global.setLocaleMessage(localeCode, {})
            return Promise.resolve()
        })
    },
    async setLocaleAsync (context, localeCode) {
        const p = []
        const parts = localeCode.split('-', 2)

        if (!loadedLocales.includes(localeCode)) {
            if (!loadedLocales.includes(parts[0])) {
                loadedLocales.push(parts[0])
                p.push(context.dispatch('loadLocaleAsync', parts[0]))
            }

            if (parts.length > 1) {
                loadedLocales.push(localeCode)
                p.push(context.dispatch('loadLocaleAsync', localeCode))
            }
        }

        return Promise.all(p)
        .then(() => {
            i18n.global.locale.value = localeCode

            context.commit('setLocaleCode', localeCode)
        })
    },

    async toggleFavorite (context, merchantId) {
        return httpRequest(apiUrl(
            context.state,
            '/offers/favorites',
            {merchantId}
        ))
        .then(data => {
            context.commit('setFavorites', data.has > 0)
            if (data.state) {
                context.commit('setFavoriteOn', merchantId)
            } else {
                context.commit('setFavoriteOff', merchantId)
            }
        })
    },
    setMembership (context, membership) {
        if (membership.id !== context.state.accessId) {
            context.commit('setAccessId', membership.id)
            context.commit('setProgramId', membership.program_id)
        }
    },

    async loadCountries (context) {
        if (context.state.countries !== null) {
            return Promise.resolve()
        }

        return httpRequest('data/countries.json')
        .then(data => {
            context.commit('setCountries', data)
        })
        .catch(() => {
            context.commit('setCountries', {})
        })
    },
    async loadTimes (context) {
        if (context.state.times !== null) {
            return Promise.resolve()
        }

        return httpRequest('data/times.json')
        .then(data => {
            context.commit('setTimes', data)
        })
        .catch(() => {
            context.commit('setTimes', {})
        })
    },

    dataListUp (context, dataId) {
        let dataList = context.getters.dataList(dataId)
        dataList = {...dataList}

        if (dataList.selectedIndex === -1) {
            dataList.selectedIndex = dataList.options.length - 1
        } else {
            --dataList.selectedIndex
        }

        context.commit('setDataList', dataList)
    },
    dataListDown (context, dataId) {
        let dataList = context.getters.dataList(dataId)
        dataList = {...dataList}

        if (dataList.selectedIndex === (dataList.options.length - 1)) {
            dataList.selectedIndex = -1
        } else {
            ++dataList.selectedIndex
        }

        context.commit('setDataList', dataList)
    },
    dataListEnter (context, dataId) {
        let dataList, option

        dataList = context.getters.dataList(dataId)
        dataList = {...dataList}

        if (dataList.selectedIndex !== -1) {
            option = dataList.options[dataList.selectedIndex]
        } else {
            option = null
        }

        dataList.options = []
        dataList.selectedIndex = -1

        context.commit('setDataList', dataList)

        return Promise.resolve(option)
    },
    dataListFocus (context, dataId) {
        let dataList = context.getters.dataList(dataId)

        dataList = {...dataList, show: true}

        context.commit('setDataList', dataList)
    },
    dataListBlur (context, dataId) {
        setTimeout(() => {
            let dataList = context.getters.dataList(dataId)
            dataList = {...dataList, show: false}
            context.commit('setDataList', dataList)
        }, 300)
    },

    async loadProgramAsync (context, override = false) {
        if (!override && context.state.program !== null) {
            return Promise.resolve()
        }

        /* if (context.state.programId === null) {
            context.commit('setProgram', null)
            return Promise.resolve()
        } */

        if (context.state.program &&
            context.state.programId &&
            context.state.program.id === context.state.programId
        ) {
            return Promise.resolve()
        }

        return httpRequest(apiUrl(context.state, '/program'))
        .then(data => {
            context.commit('setProgram', data)
            context.commit('setProgramId', data.id)
        })
        .catch(() => {
            context.commit('setProgram', null)
            context.commit('setProgramId', null)
        })
    },
    async loadCategoriesAsync (context, override = false) {
        if (!override && context.state.categories !== null) {
            return Promise.resolve()
        }

        return httpRequest(apiUrl(context.state, '/categories'))
        .then(data => {
            context.commit('setCategories', data)
        })
        .catch(() => {
            context.commit('setCategories', null)
        })
    },
    async loadMembershipsAsync (context, override = false) {
        if (!override && context.state.memberships !== null) {
            return Promise.resolve()
        }

        return httpRequest(apiUrl(context.state, '/members/memberships'))
        .then(data => {
            context.commit('setMemberships', data)
        })
        .catch(() => {
            context.commit('setMemberships', null)
        })
    },
    async loadOffersAsync (context, override = false) {
        if (!override && context.state.offers !== null) {
            return Promise.resolve()
        }

        context.commit('setOffersScroll', 0)

        const filterPath = (context.state.offersFilter ? '/' + context.state.offersFilter : '')

        if (filterPath === '' &&
            context.state.offersIndex === 0
        ) {
            await setSearchMode(context.state.searchMode)
        }

        return httpRequest(apiUrl(context.state, '/offers/index' + filterPath))
        .then(data => {
            if (context.state.offersFilter === 'savings') {
                context.commit('setOffersSavings', data)
            } else if (context.state.offersFilter === 'favorites') {
                context.commit('setOffersFavorites', data)
            } else {
                context.commit('setOffers', data)
            }
        })
        .catch(() => {
            if (context.state.offersFilter === 'savings') {
                context.commit('setOffersSavings', null)
            } else if (context.state.offersFilter === 'favorites') {
                context.commit('setOffersFavorites', null)
            } else {
                context.commit('setOffers', null)
            }
        })
    },
    async loadOffersInlineAsync (context, override = false) {
        if (!override && context.state.offersInline !== null) {
            return Promise.resolve()
        }

        if (context.state.offersFilter) {
            return Promise.resolve()
        }

        return httpRequest(apiUrl(context.state, '/offers/index/inline'))
        .then(data => {
            context.commit('setOffersInline', data)
        })
        .catch(() => {
            context.commit('setOffersInline', null)
        })
    },
    async loadOfferAsync (context, override = false) {
        if (!override && context.state.offer !== null) {
            return Promise.resolve()
        }

        return httpRequest(apiUrl(context.state, '/offers/item'))
        .then(data => {
            context.commit('setOffer', data)
        })
        .catch(() => {
            context.commit('setOffer', null)
        })
    },
    async loadAdditionalOffersAsync (context, override = false) {
        if (!override && context.state.additionalOffers !== null) {
            return Promise.resolve()
        }

        return httpRequest(apiUrl(context.state, '/offers/additional'))
        .then(data => {
            context.commit('setAdditionalOffers', data)
        })
        .catch(() => {
            context.commit('setAdditionalOffers', null)
        })
    },
    async loadDataSource (context, payload) {
        switch (payload.source) {
            case 'cities':
            case 'keywords':
            case 'car-rental-locations':
                break
            default:
                return Promise.reject(
                    new Error('Invalid data source. (' + payload.source + ')')
                )
        }

        return httpRequest(apiUrl(context.state, '/options/' + payload.source, {
            search: payload.search
        }))
        .then(data => {
            let dataList = context.getters.dataList(payload.id)
            dataList = {...dataList}

            if (data instanceof Array) {
                dataList.options = data
                dataList.totalCount = data.length
            } else if ('items' in data) {
                dataList.options = data.items

                if ('total_count' in data) {
                    dataList.totalCount = data.total_count
                } else {
                    dataList.totalCount = data.items.length
                }
            } else {
                dataList.options = []
                dataList.totalCount = 0
            }

            context.commit('setDataList', dataList)
        })
        .catch(() => {
            let dataList = context.getters.dataList(payload.id)
            dataList = {...dataList}

            dataList.options = []
            context.commit('setDataList', dataList)
        })
    },
    async loadPageAsync (context, override = false) {
        if (!override && context.state.page !== null) {
            return Promise.resolve()
        }

        if (context.state.pageAlias === null) {
            context.commit('setPage', null)
            return Promise.resolve()
        }

        return httpRequest(apiUrl(context.state, '/content/' + context.state.pageAlias))
        .then(data => {
            context.commit('setPage', data)
        })
        .catch(() => {
            context.commit('setPage', null)
        })
    },

    async updateOffersAsync (context, override = false) {
        const p = []

        let loading = false

        if (!context.state.loading) {
            context.commit('setLoading', true)
            loading = true
        }

        p.push(context.dispatch('loadOffersAsync', override))
        p.push(context.dispatch('loadOffersInlineAsync', override))

        return Promise.all(p)
        .then(() => {
            if (loading) {
                context.commit('setLoading', false)
            }
        })
    },
    async updateOfferAsync (context) {
        const p = []

        let loading = false

        if (!context.state.loading) {
            context.commit('setLoading', true)
            loading = true
        }

        p.push(context.dispatch('loadOfferAsync', true))

        return Promise.all(p)
        .then(() => {
            if (loading) {
                context.commit('setLoading', false)
            }
        })
    },
    async updatePageAsync (context) {
        const p = []

        let loading = false

        if (!context.state.loading) {
            context.commit('setLoading', true)
            loading = true
        }

        p.push(context.dispatch('loadPageAsync', true))

        return Promise.all(p)
        .then(() => {
            if (loading) {
                context.commit('setLoading', false)
            }
        })
    }
}

// Getters are like computed properties
const getters = {
    // Config
    option: (_state, _getters) => (key = null) => {
        if (key === null) {
            return config.options
        }

        if (!(key in config.options)) {
            return null
        }

        return config.options[key]
    },
    page: (_state, _getters) => (name, key) => {
        if (!config.pages ||
            !config.pages[name] ||
            !(key in config.pages[name])
        ) {
            return 'none'
        }

        return config.pages[name][key]
    },
    dataList: (state, _getters) => (id, key) => {
        let dataList

        if (id in state.dataLists) {
            dataList = state.dataLists[id]
        } else {
            dataList = {
                id,
                show: false,
                options: [],
                totalCount: 0,
                selectedIndex: -1
            }
        }

        if (key) {
            if (key in dataList) {
                return dataList[key]
            }

            return null
        }

        return dataList
    },

    toggle: (state, _getters) => (group, toggle) => {
        if (typeof group === 'string' && group !== '') {
            if (!state.toggles[group]) {
                return false
            }

            return (state.toggles[group] === toggle)
        }

        return (state.toggles[toggle] === true)
    },

    /* inlineCategories: (state, getters) => () => {

    }, */

    // Locales
    locales: (_state, _getters) => (key = null) => {
        if (key === null) {
            const locales = []

            Object.keys(config.locales).forEach(key => {
                locales.push({
                    code: key,
                    shortCode: key.split('-')[0],
                    name: config.locales[key].name,
                    shortName: config.locales[key].shortName
                })
            })

            return locales
        }

        if (!(key in config.locales)) {
            return null
        }

        return config.locales[key]
    },
    shortLocales: (_state, getters) => () => {
        const locales = []
        const shortCodes = []

        getters.locales().forEach(locale => {
            if (shortCodes.indexOf(locale.shortCode) === -1) {
                locales.push({
                    code: locale.shortCode,
                    name: locale.shortName
                })
                shortCodes.push(locale.shortCode)
            }
        })

        return locales
    },
    localeShortCode: (state, _getters) => () => {
        return state.localeCode.split('-', 2)[0]
    },
    memberLocaleCode: (state, getters) => (fallback = null) => {
        let localeCode = fallback

        if (state.member) {
            localeCode = state.member.locale
        } else if (fallback === null) {
            return null
        }

        if (localeCode in config.locales) {
            return localeCode
        }

        localeCode = localeCode.split('-', 2)[0]

        for (const locale of getters.locales()) {
            if (locale.shortCode === localeCode) {
                return localeCode
            }
        }

        return fallback
    },
    localeCodeFromShortCode: (state, getters) => localeShortCode => {
        const member = state.member

        let localeCode = getters.memberLocaleCode()

        if (localeCode &&
            localeCode.split('-', 2)[0] !== localeShortCode
        ) {
            localeCode = null
        }

        if (!localeCode) {
            if (member && member.pczip) {
                // Check for country specific locale based on pczip
                if (/^([0-9]{1,5})(-?[0-9]{4})?$/.test(member.pczip)) {
                    localeCode = localeShortCode + '-US'
                } else {
                    localeCode = localeShortCode + '-CA'
                }

                if (!(localeCode in config.locales)) {
                    localeCode = null
                }
            }
        }

        if (!localeCode) {
            for (const locale of getters.locales()) {
                if (locale.shortCode === localeShortCode) {
                    localeCode = locale.code
                    break
                }
            }
        }

        if (!localeCode) {
            return localeShortCode
        }

        return localeCode
    },
    logoImage: (_state, getters) => () => {
        return 'images/logo-' + getters.localeShortCode() + '.png'
    },
    url: (state, _getters) => path => {
        let url, urlSite

        if (path.substr(0, 1) === '/') {
            urlSite = state.urlSite.split('/')

            // Remove and sub directories
            urlSite = urlSite.slice(0, 3)

            urlSite = urlSite.join('/')

            url = urlSite + path
        } else {
            url = path
        }

        return url
    },
    hasSearch: (state, _getters) => () => {
        if (state.searchPcZip !== null ||
            state.searchCity !== null ||
            state.searchKeywords !== null ||
            state.searchLongitute !== null ||
            state.searchLatitude !== null
        ) {
            return true
        }

        return false
    }
}

export default createStore({
    state,
    mutations,
    actions,
    getters,
    modules: {
    }
})
