import {createI18n} from 'vue-i18n'

export default createI18n({
    legacy: false,
    locale: 'en-US',
    fallbackLocale: {
        'en-CA': ['en'],
        'en-US': ['en'],
        'fr-CA': ['fr', 'en-CA', 'en']
    },
    silentTranslationWarn: true,
    silentFallbackWarn: true
})
