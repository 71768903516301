const Home = () => import('@/views/SweetHome.vue')

// Access
// TODO: See if production version loads as slowly as dev
// import AccessLogin from '@/views/access/Login'
const AccessLogin = () => import('@/views/access/SweetLogin.vue')
const AccessRegister = () => import('@/views/access/SweetRegister.vue')
const AccessForgotPassword = () => import('@/views/access/SweetForgotPassword.vue')

// Offers
const OfferIndex = () => import('@/views/offers/SweetIndex.vue')
const OfferItem = () => import('@/views/offers/SweetItem.vue')
const OfferTravel = () => import('@/views/offers/SweetTravel.vue')
const OfferSavings = () => import('@/views/offers/SweetSavings.vue')

const Profile = () => import('@/views/members/SweetProfile.vue')

const Faq = () => import('@/views/service/SweetFaq.vue')
const Contact = () => import('@/views/service/SweetContact.vue')

const Page = () => import('@/views/SweetPage.vue')

const offerItemProps = route => {
    return {
        offerId: parseInt(route.params.offerId),
        locationId: parseInt(route.params.locationId || 0)
    }
}

const pageProps = route => {
    return {
        pageAlias: route.params.pageAlias
    }
}

export default [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/login',
        name: 'login',
        component: AccessLogin
    },
    {
        path: '/register',
        name: 'register',
        component: AccessRegister
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: AccessForgotPassword
    },
    {
        path: '/offers',
        name: 'offer-index',
        component: OfferIndex
    },
    {
        path: '/offers/favorites',
        name: 'offer-index-favorites',
        component: OfferIndex,
        props: {
            favorites: 1
        }
    },
    {
        path: '/offers/savings',
        name: 'offer-index-savings',
        component: OfferIndex,
        props: {
            savings: 1
        }
    },
    {
        path: '/savings',
        name: 'offer-savings',
        component: OfferSavings
    },
    {
        path: '/offers/travel',
        name: 'offer-travel',
        component: OfferTravel
    },
    {
        path: '/offers/:offerId',
        name: 'offer-item',
        component: OfferItem,
        props: offerItemProps
    },
    {
        path: '/offers/:offerId/:locationId',
        name: 'offer-item-location',
        component: OfferItem,
        props: offerItemProps
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile
    },
    {
        path: '/faq',
        name: 'faq',
        component: Faq
    },
    {
        path: '/page/:pageAlias',
        name: 'page',
        component: Page,
        props: pageProps
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact
    }
]
