import clearToken from './clearToken.js'
import httpRequest from './httpRequest.js'
import store from './../store'

export default async () => {
    const token = window.localStorage.getItem('bearer-token')

    if (token === null) {
        return Promise.reject(new Error('Token is null.'))
    }

    const url = store.state.urlApi + '/access/logout'

    return httpRequest(url, {
        method: 'GET'
    })
    .then(() => {
        clearToken()
    })
    .catch(error => {
        clearToken()

        return Promise.reject(error)
    })
}
