import isOfferIndexRoute from './isOfferIndexRoute.js'

export default function (routeName) {
    if (isOfferIndexRoute(routeName)) {
        return true
    }

    switch (routeName) {
        case 'offer-item':
        case 'offer-item-location':
        case 'offer-travel':
        case 'offer-savings':
        case 'profile':
        case 'faq':
        case 'page':
        case 'contact':
            return true
        default:
            return false
    }
}
